<script setup>
import {reactive, ref, inject, nextTick, computed, watch, toRefs} from "vue";
import editor from "@tinymce/tinymce-vue";
import VClamp from "vue-clamp";
import markdown from "vue-markdown";
import defaults from "../../constants/defaults";
import multiselect from "vue-multiselect";
import Avatar from "vue-avatar";
import DatePicker from "vue2-datepicker";
import {useStore} from "vue2-helpers/vuex";
import publicFilesService from "../../services/public-files.service";
import filesService from "../../services/files.service";
import {download} from "../../helpers/file";
import {decodeDate, encodeDate, encodeDateForServer} from "../../helpers/date";
import {completeLink, getYoutubeEmbeddedUrl, isYoutubeLink} from "../../helpers/string";
import CustomLinksEditModal from "./CustomLinksEditModal.vue";

const props = defineProps({
    field:  Object,
    initValue: Object,

    instantUpdate: {
        type: Boolean,
        default: true,
    },

    editable: {
        type: Boolean,
        default: true,
    },

    fromTable: {
        type: Boolean,
        default: false,
    }
});

const {initValue, field} = toRefs(props);
const {fromTable, editable, instantUpdate} = props;

const emit = defineEmits(['valueChanged']);
const saveValueMethod = inject('saveCustomValue', null);
const saveCustomFile = inject('saveCustomFile', null);
const store = useStore();

const loading = ref(false);
const editMode = ref(false);
const watchValue = ref(true);
const persistEditMode = ref(false);
const value = reactive({
        text: "",
        options: [],
        links: [],
        files: [],
    });

const editorFocused = ref(false);
const featuresClamped = ref(false);
const valueChanged = ref(false);
const initLocalValue = ref(null);
const singleOption = ref(null);
const showFileUploadModal = ref(false);
const newFile = ref(null);
const rawDate = ref(null);
const editLinksModal = ref(null);
const currentYoutubeUrl = ref(null);
const showYoutubeVideoModal = ref(null);

const editorOptions = computed(() => fromTable ? defaults.editorOptionsEmpty : defaults.editorOptions);

const clonedValue = computed(() => {
    return {
        text: value.text,
        options: [...value.options],
        links: [...value.links],
        files: [...value.files],
    }
})

watch(initValue, (value) => {
    if (value) {
        initLocalValue.value = value;
    } else {
        initLocalValue.value = {
            text: "",
            options: [],
            links: [],
            files: [],
        };
    }

    resetValue();
}, {immediate: true});

watch(value, () => {
    if (watchValue.value) {
        if (!instantUpdate) {
            emit("valueChanged", clonedValue.value);
        } else {
            if (editMode.value && ['select', 'multiselect', 'users_list', 'pipeline'].includes(field.value.type)) {
                saveValue();
            }
        }

        valueChanged.value = true;
    }
}, {deep: true});

watch(singleOption, () => {
    if (singleOption.value) {
        value.options = [singleOption.value];
    } else {
        value.options = [];
    }
});

watch(editMode, (value) => {
    if (!value) {
        persistEditMode.value = false;
    }
});

watch(rawDate, () => {
    value.text = decodeDate(rawDate.value);
});

function resetValue() {
    watchValue.value = false;
    singleOption.value = null;

    value.text = initLocalValue.value.text;

    if (field.value.type === 'date') {
        if (value.text) {
            rawDate.value = encodeDate(value.text);
        } else {
            rawDate.value = null;
        }
    }

    if (initLocalValue.value.cleared_text) {
        value.cleared_text = initLocalValue.value.cleared_text ?? '';
        featuresClamped.value = true;
    }

    if ('options' in initLocalValue.value) {
        value.options = [...initLocalValue.value.options];
    }

    if ('files' in initLocalValue.value) {
        value.files = [...initLocalValue.value.files];
    }

    if ('links' in initLocalValue.value) {
        value.links = [...initLocalValue.value.links];
    }

    if (field.value.type === 'select' || field.value.type === 'pipeline' || field.value.type === 'POC_preset') {
        if (initLocalValue.value.options.length) {
            singleOption.value = initLocalValue.value.options[0]
        }
    }

    nextTick(() => {
        watchValue.value = true;
    });
}

function clickedOutside() {
    if (valueChanged.value && (field.value.type !== 'textarea' || !editorFocused.value) && field.value.type !== 'date' && field.value.type !== 'links_list') {
        saveValue();

        if (field.value.type === 'textarea') {
            editMode.value = false;
        }
    } else {
        editMode.value = false;
    }
}

function onMouseOver() {
    if (fromTable && editable &&!loading.value && ['text', 'numeric', 'select', 'multiselect', 'users_list'].includes(field.value.type)) {
        editMode.value = true;
    }
}

function onMouseLeave() {
    if (['text', 'textarea', 'select', 'multiselect', 'users_list', 'links_list'].includes(field.value.type) && fromTable && editable && !persistEditMode.value) {
        editMode.value = false;
    }
}

async function saveValue() {
    if (editMode.value && instantUpdate && field.value.type !== 'file') {
        loading.value = true;
        editMode.value = false;

        if (field.value.type === 'date') {
            value.text = encodeDateForServer(rawDate.value);
        }

        const {data} = await saveValueMethod(field.value.id, value)
        loading.value = false;
        valueChanged.value = false;

        if (Object.keys(data.errors).length) {
            for (let errorField in data.errors) {
                data.errors[errorField].forEach(error => {
                    alert(error)
                })
            }
        } else {
            initLocalValue.value = data.value;
            emit("valueChanged", field.value.id, clonedValue.value);
        }

        resetValue();
    }
}

async function downloadFile(file) {
    const {data} = await (
        store.getters.isStartup
            ? publicFilesService.downloadCustomFile(file.id)
            : filesService.downloadCustomFile(file.id)
    )

    if (data.type === 'application/pdf') {
        window.open('/files/' + file.id, '_blank')
    } else {
        download(data, file.file_name);
    }
}

async function deleteFile(file, index) {
    if (confirm("Delete file?")) {
        await filesService.deleteCustomFile(file.id);
        value.files.splice(index, 1);
        emit("valueChanged", field.value.id, value);
    }
}

async function handleFileUpload() {
    let files = newFile.value.files;

    if (instantUpdate) {
        let formData = new FormData();

        for (const file of files) {
            if (typeof file === "object") {
                formData.append('file[]', file);
            }
        }

        const {data} = await saveCustomFile(field.value.id, formData);
        emit("valueChanged", field.value.id, data);

        initLocalValue.value = data;
        resetValue();
    } else {
        for (let i in files) {
            if (typeof files[i] === "object") {
                files[i].id = 0
                files[i].file_name = files[i].name
                files[i].user_name = store.state.user.name
                files[i].user_avatar = store.state.user.avatar
                value.files.push(files[i])
            }
        }
    }

    showFileUploadModal.value = false;
}

function saveLinks(links) {
    value.links = links
    editMode.value = true;
    saveValue();
}

function showYoutubeVideo(url) {
    currentYoutubeUrl.value = getYoutubeEmbeddedUrl(url);
    showYoutubeVideoModal.value = true;
}

</script>

<template>
    <div
        :class="editable ? 'min-30' : ''"
        @click="() => {if (editable) editMode = true}"
        @keydown.esc="resetValue"
        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave"
        v-click-outside="clickedOutside"
    >
        <BSpinner
            v-if="loading"
            class="mr-1"
            variant="secondary"
            small
        />

        <template v-if="field.type === 'text' || field.type === 'numeric'">

            <template v-if="editMode">
                <input
                    v-if="field.type === 'numeric'"
                    v-model="value.text"
                    type="number"
                    :min="field.min_value"
                    :max="field.max_value"
                    :disabled="!editable"
                    class="form-control--mid form-control"
                    @keyup.enter="saveValue"
                    @input="persistEditMode = true"
                >

                <input
                    v-else
                    v-model="value.text"
                    type="text"
                    class="form-control--mid form-control"
                    @keyup.enter="saveValue"
                    @input="persistEditMode = true"
                >
            </template>

            <template v-else>
                <template v-if="value.text.length">
                    <template v-if="field.type === 'numeric'">
                        {{parseInt(value.text).toLocaleString()}}
                    </template>

                    <template v-else>
                        {{value.text}}
                    </template>
                </template>
            </template>

            <a
                v-if="!fromTable && editable && !editMode && !value.text.length"
                class="link"
                @click.stop="editMode = true"
            >
                Add
            </a>

        </template>

        <template v-if="field.type === 'textarea'">
            <editor
                v-if="editMode"
                v-model="value.text"
                :apiKey="apiKeys.tinyMCE"
                :init="editorOptions"
                :disabled="!editable"
                @onFocus="editorFocused = true"
                @onBlur="editorFocused = false"
                @input="persistEditMode = true"
            >
            </editor>

            <div v-show="!editMode">
                <template v-if="field.markdown">
                    <v-clamp autoresize :max-lines="3" v-if="featuresClamped"> {{value.cleared_text}}
                        <template #after>
                            <a v-if="featuresClamped" class="link-normal" @click="featuresClamped = false"> more</a>
                        </template>
                    </v-clamp>

                    <template v-else>
                        <markdown>
                            {{value.text}}
                        </markdown>

                        <a class="link-normal" @click="featuresClamped = true"> less</a>
                    </template>
                </template>

                <div v-else-if="value.text.length" v-html="value.text" v-bind:class="{'line-clamp':fromTable, 'line-clamp1':fromTable}"/>

                <template v-else>
                    <a v-if="!fromTable && editable" class="link">
                        Add
                    </a>
                </template>
            </div>
        </template>

        <template v-else-if="['select', 'multiselect', 'users_list', 'pipeline', 'POC_preset'].includes(field.type)">
            <template v-if="(editMode || !fromTable) && editable">
                <multiselect
                    v-if="field.type === 'select' || field.type === 'pipeline' || field.type === 'POC_preset'"
                    v-model="singleOption"
                    :options="field.options"
                    label="value"
                    track-by="value"
                    class="multiselect-mid"
                    :title="(singleOption) ? singleOption.value : ''"
                    @input="editMode = true"
                />

                <multiselect
                    v-else
                    v-model="value.options"
                    :multiple="true"
                    :options="field.options"
                    label="value"
                    track-by="value"
                    class="multiselect-mid"
                    @input="editMode = true"
                />
            </template>

            <template v-else>
                <template v-if="value.options.length">
                    <template v-for="(option, index) in value.options">
                        {{ option.value }}
                        <template v-if="index < value.options.length - 1">, </template>
                    </template>
                </template>
            </template>
        </template>

        <div class="tags-list tags-list--light">
            <template v-if="field.type === 'file'">
                <span v-for="(file, index) in value.files" :key="index" class="tags-list__tag">
                    <div class="image-block image-block--rounded image-block--rounded--xxs">
                         <Avatar
                             :username="file.user_name"
                             :src="file.user_avatar"
                             :title="file.file_info"
                             :size="27"
                         />
                    </div>

                    <template v-if="file.id">
                        <a
                            :title="file.file_info"
                            @click="downloadFile(file)"
                        >
                            {{ file.file_name }}
                        </a>
                        <a
                            v-if="editable && file.user_can_delete"
                            class="tags-list__tag__close"
                            @click="deleteFile(file, index)"
                        ></a>
                    </template>

                    <template v-else>
                        <a :title="file.name">{{file.name}}</a>
                        <a
                            v-if="editable"
                            class="tags-list__tag__close"
                            @click="value.files.splice(index, 1)"
                        ></a>
                    </template>
                </span>

                <template v-if="editable">
                    <a @click="showFileUploadModal = true" class="link">
                        Add File
                    </a>

                    <b-modal v-model="showFileUploadModal" title="Upload File" size="md" hide-footer>
                        <input
                            type="file"
                            multiple
                            ref="newFile"
                            @change="handleFileUpload()"
                        >
                        <br>
                        <br>
                    </b-modal>
                </template>
            </template>
        </div>

        <template v-if="field.type === 'date'">
            <template v-if="editMode">
                <DatePicker
                    v-model="rawDate"
                    :disabled="!editable"
                    :append-to-body="false"
                    format = "MM/DD/YYYY"
                    @change="saveValue"
                />
            </template>

            <template v-else>
                <template v-if="value.text">
                    {{value.text}}
                </template>

                <template v-else>
                    <template v-if="!fromTable && editable">
                        <a
                            class="link"
                            @click.stop="editMode = true"
                        >
                            Add
                        </a>
                    </template>
                </template>
            </template>
        </template>

        <template v-if="field.type === 'links_list'">
            <div v-for="(link, index) in value.links" :key="index" class="links-controls">
                <a
                    v-if="isYoutubeLink(link.url)"
                    class="youtube-url"
                    @click.stop="showYoutubeVideo(link.url)"
                >
                    {{link.title}}
                </a>

                <a v-else
                   :href="completeLink(link.url)"
                   target="_blank"
                   @click.stop
                >
                    {{link.title}}
                </a>
            </div>

            <a
                v-if="editable"
                class="link-wrapper"
                @click.stop="editLinksModal.open(value.links)"
            >
                <template v-if="value.links.length"><span class="link-wrapper__edit">Edit</span></template>
                <template v-else><span class="link-wrapper__add">Add</span></template>
                Links
            </a>

            <CustomLinksEditModal
                ref="editLinksModal"
                @saved="saveLinks"
            />

            <b-modal v-model="showYoutubeVideoModal" modal-class="modal-transparent" :hide-header="true" :hide-footer="true" size="md">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe width="420" height="315" :src="currentYoutubeUrl"></iframe>
                </div>
            </b-modal>
        </template>
    </div>
</template>

<style scoped>

</style>
